import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, Container, Form, FormGroup, Table, Label, Input } from 'reactstrap';
import AppNavbar from './AppNavbar';
import loggoutUser from './Logout';
import Autocomplete from 'react-autocomplete';
import toastr from 'toastr';
import './Autocomplete.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from './validator';
import StavkeChildComponent from './StavkeChildComponent'
import BACKEND_URL from './Config.js';

//matchDijagnozaOznaka
//matchCjenovnikPunaOznaka

function matchCjenovnikPunaOznaka(state, value) {

    let r = state.punaOznaka.toString().toLowerCase().indexOf(value.toLowerCase()) !== -1;
    return r;
}
function matchCjenovnikNaziv(state, value) {

    let r = state.naziv.toString().toLowerCase().indexOf(value.toLowerCase()) !== -1;
    return r;
}

function matchCjenovnikPunaOznaka2(state, value) {

    let r = state.punaOznaka.toString().indexOf(value.toString()) !== -1;
    /*
    if(r==true)
    {
        console.log("OZNAKA VALUE: "+ value);
        console.log("OZNAKA STATE: "+ state.punaOznaka);
        console.log("OZNAKA R: "+ r);
    }*/

    return r;
}
/*
function matchCjenovnikNaziv2(state, value) {
    
    let r = state.naziv.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    return r;
}*/
/*
function matchCjenovnikNaziv2(state,value)
{
    let r = state.naziv.toString().toLowerCase().indexOf(value.toLowerCase()) !== -1;
    return r;
}*/
/*
function matchCjenovnikNaziv2(state,value)
{
    let r = state.srp.toString().toLowerCase().indexOf(value.toLowerCase()) !== -1;
    return r;
}*/

function matchDijagnozaOznaka(state, value) {

    let r = state.oznaka.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    return r;
}
function matchDijagnozaNaziv(state, value) {

    let r = state.srp.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    return r;
}
function matchOznaka(state, value) {

    let r = state.oznaka.toString().toLowerCase().indexOf(value.toString().toLowerCase()) !== -1;
    return r;
}

function getRandomNumStr() {
    return (Math.floor(Math.random() * 9999) + 1).toString();
}

function matchNaziv(state, value) {

    return (
        state.naziv.toLowerCase().indexOf(value.toLowerCase()) !== -1 //||
        //state.sifarnik_zdravstvenih_ustanova.naziv.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
}

class Izvjestaji extends Component {


    izvjestaj_obj = {
        ustanova_uputilac_sifra: "",
        ustanova_uputilac_naziv: "",
        uputnica_tip: "S",
        ljekar_oznaka: "",
        ljekar_ime_prezime: "",
        rjesenje_broj: "",
        dijagnoza_oznaka: "",
        dijagnoza_naziv: "",
        lijecenje_period_od: "",
        lijecenje_period_do: "",
        osiguranik_jmb: "",
        osiguranik_ime_prezime: "",
        participacija_placa: "D",
        rok_placanja: "",
        valuta: "",
        datum: "",
        broj_izvjestaja: "",
        broj_protokola: "",
        rok_placanja: "",
        grupa_djelatnosti_oznaka: "",
        grupa_djelatnosti_naziv: "",
        nivo_djelatnosti: "8",
        broj: "0",
        nova_stavka_naziv_usluge: "",
        id: null,
        stavke: [],
        nova_stavka: { usluga_puna_oznaka: "", usluga_history_id: "", usluga_cjenovnik_id: "", idUsluge: null, id: null, usluga_grupa: "", usluga_nivo: "", usluga_oznaka: "", usluga_naziv: "", usluga_bodova: "", ljekar_sifra: "K0656", usluga_participacija_iznos: "", usluga_participacija_placa: "" }
    };


    checkIfLogged() {
        fetch(BACKEND_URL + '/api/user/me', {
            method: 'GET',
            headers:
            {
                'credentials': 'include'
            },
            credentials: 'include'
        })
            .then(response => {
                if (response.status == 200) {
                    console.log("IS LOGGED");
                }
                else {
                    console.log("IS NOT LOGGED");
                    this.props.history.push('/');
                }
            }
            ).catch(error => {
                console.log("IS NOT LOGGED ERROR");
                console.log(error);
                this.props.history.push('/');
            }
            )
    }


    constructor(props) {
        super(props);

        //this.matchOznaka = this.matchOznaka.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleOznakaChange = this.handleOznakaChange.bind(this);
        this.getDoctorName = this.getDoctorName.bind(this);
        this.handleUputnicaTipChange = this.handleUputnicaTipChange.bind(this);
        this.validateInputs = this.validateInputs.bind(this);
        this.handleGrupaDjelatnostiChange = this.handleGrupaDjelatnostiChange.bind(this);
        this.handleNovaStavkaAdd = this.handleNovaStavkaAdd.bind(this);
        this.handleLjekarSifra = this.handleLjekarSifra.bind(this);
        this.obrisiStavku = this.obrisiStavku.bind(this);
        this.sacuvajIzvjestaj = this.sacuvajIzvjestaj.bind(this);
        this.handlePPChange = this.handlePPChange.bind(this);
        this.first50 = this.first50.bind(this);
        this.previous50 = this.previous50.bind(this);
        this.next50 = this.next50.bind(this);
        this.last50 = this.last50.bind(this);
        this.izmjeniIzvjestaj = this.izmjeniIzvjestaj.bind(this);
        this.noviIzvjestaj = this.noviIzvjestaj.bind(this);
        this.obrisiIzvjestaj = this.obrisiIzvjestaj.bind(this);
        this.handleInputChange2 = this.handleInputChange2.bind(this);
        this.checkIfLogged = this.checkIfLogged.bind(this);
        this.fetchIzv = this.fetchIzv.bind(this);
        this.countThosePages = this.countThosePages.bind(this);

        this.checkIfLogged();
        let loading = {
            izvjestaji_history: true,
            cjenovnik: true,
            dijagnoze: true,
            sifarnik_zdravstvenih_ustanova: true,
        }
        this.state = ({ loading: { ...loading }, datum_dnevni: '', datex: new Date(), datum_od_za_arhivu: "", datum_do_za_arhivu: "", currentPosition: 0, maxPosition: 0, izvjestaji_history: [], sifarnik_zdravstvenih_ustanova: [], cjenovnik: [], dijagnoze: [], izvjestaj: { ...this.izvjestaj_obj } })

    }

    getDoctorName(oznakaVal)
    {
        fetch(BACKEND_URL + '/api/doktor/getByOznaka?oznaka='+oznakaVal, {
            method: 'GET',
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                credentials: 'include'
            },
            credentials: 'include',
        }).then(response => {
            if (response.status == 200) {
                return response.json();
            }
            else {
                console.log("Nije uspjelo dobaviti ime doktora za odredjenu sifru")
                return;
            }
        }).then(data => {
            if(data['pronaslo']==true && !data['imeIPrezime']=="")
            {
                let izv = { ... this.state.izvjestaj };
                izv['ljekar_ime_prezime']=data['imeIPrezime'];
                this.setState({ izvjestaj: izv });
                
            }
            else
            {
            }
        })
            .catch(error => {
                console.log(error)
                return;
            });
    }

    handleOznakaChange(event) {
        let val = event.target.value;
        let name = event.target.name;
        let izv = { ... this.state.izvjestaj };
        izv[name] = val;
        if(val.length>=5)
        {
            this.getDoctorName(val);
        }
        this.setState({ izvjestaj: izv });

    }

    obrisiStavku(event) {
        this.checkIfLogged();
        let indeks = event.target.name;
        indeks = Number(indeks);
        let ta_stavka = { ...this.state.izvjestaj.stavke[indeks] };
        let izv = { ...this.state.izvjestaj };
        let stavkane = [... this.state.izvjestaj.stavke]
        if (ta_stavka.usluga_history_id == null || izv.id == null) {
            //console.log("INDEKS:");
            //console.log(indeks);
            //console.log(stavkane);
            stavkane.splice(indeks, 1);
            izv.stavke = [...stavkane];

            this.setState({ izvjestaj: { ...izv } });
            //this.first50(null);
            toast.warn("Stavka je obrisana!", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;

        }
        else {
            fetch(BACKEND_URL + '/api/data/izvjestaj/' + this.state.izvjestaj.id + '/' + ta_stavka.usluga_history_id, {
                method: 'DELETE',
                headers:
                {
                    'credentials': 'include'
                },
                credentials: 'include'
            }).then(data => {

                stavkane.splice(indeks, 1);
                izv.stavke = [...stavkane];

                this.setState({ izvjestaj: { ...izv } });
                this.first50(null);
                toast.warn("Stavka je obrisana!", {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            })
                .catch(error => { console.log(error) });
        }
        //console.log("PRIMILO SE: "+ name.toString());
    }

    obrisiIzvjestaj(event) {
        this.checkIfLogged();
        let u_sure = window.confirm("Da li ste sigurni da želite da obrišete ovaj izvještaj?");
        if (u_sure == false) {
            return;
        }
        if (this.state.izvjestaj.id == null) {
            let iz = { ...this.izvjestaj_obj };
            iz.stavke = [];
            this.setState({ izvjestaj: iz });
            toast.warn("Izvještaj obrisan!", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        fetch(BACKEND_URL + '/api/data/izvjestaj/' + this.state.izvjestaj.id, {
            method: 'DELETE',
            headers:
            {
                'credentials': 'include'
            },
            credentials: 'include'
        }).then(data => {
            let iz = { ...this.izvjestaj_obj };
            iz.stavke = [];
            this.setState({ izvjestaj: iz });
            this.first50(null);
            toast.warn("Izvještaj obrisan!", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        })
            .catch(error => { console.log(error) });
    }

    noviIzvjestaj(event) {
        let iz = { ...this.izvjestaj_obj };
        iz.stavke = [];
        this.setState({ izvjestaj: iz });
    }

    handlePPChange(event) {
        let izv = { ...this.state.izvjestaj };
        izv.participacija_placa = event.target.value
        this.setState({ izvjestaj: izv });
    }
    /*
        datum:izv.datum,
                dijagnoza:izv.dijagnoza_oznaka,
                dijagnozaNaziv:izv.dijagnoza_naziv,
                iznos:suma_iznosa,
                lijecenjePeriodOd:izv.lijecenje_period_od.toString(),
                lijecenjePeriodDo:izv.lijecenje_period_do.toString(),
                ljekarImePrezime:izv.ljekar_ime_prezime,
                ljekarOznaka:izv.ljekar_oznaka,
                osiguranikImePrezime:izv.osiguranik_ime_prezime,
                osiguranikJmb:izv.osiguranik_jmb,
                participacijaIznos:suma_participacija,
                participacijaPlaca:part_plac,
                rokPlacanja:izv.rok_placanja,
                uputnicaTip:izv.uputnica_tip,
                ustanovaUputilacNaziv:izv.ustanova_uputilac_naziv,
                ustanovaUputilacSifra:izv.ustanova_uputilac_sifra,
                valuta:'BAM',
    */
    izmjeniIzvjestaj(event) {
        this.checkIfLogged();
        let n = event.target.name;
        n = Number(n);
        let izx = this.state.izvjestaji_history[n];
        let pp = "D";
        if (izx.participacijaPlaca == true) {
            pp = "D";
        }
        else {
            pp = "N";
        }
        let ls = [];
        for (let i = 0; i < izx.stavke.length; i++) {
            let uo = izx.stavke[i].uslugaPunaOznaka.substring(4, 7);
            let st = {
                usluga_history_id: izx.stavke[i].id,
                usluga_cjenovnik_id: izx.stavke[i].usluga,
                usluga_bodova: izx.stavke[i].iznos,
                ljekar_sifra: izx.stavke[i].ljekarSifra,
                usluga_kolicina: 1,
                usluga_participacija_iznos: izx.stavke[i].participacijaIznos,
                usluga_puna_oznaka: izx.stavke[i].uslugaPunaOznaka,
                usluga_grupa: izx.stavke[i].uslugaGrupa,
                usluga_naziv: izx.stavke[i].uslugaNaziv,
                usluga_nivo: izx.stavke[i].uslugaNivo,
                usluga_oznaka: uo
            }
            ls.push(st);
        }
        let prazna_stavka = { usluga_puna_oznaka: "", usluga_history_id: "", usluga_cjenovnik_id: "", idUsluge: null, usluga_grupa: "", id: null, usluga_nivo: "", usluga_oznaka: "", usluga_naziv: "", usluga_bodova: "", ljekar_sifra: "K0656", usluga_participacija_iznos: "", usluga_participacija_placa: "" }
        let lpo = "";
        let lpd = "";
        if (izx.lijecenjePeriodOd != null && izx.lijecenjePeriodOd != "") {
            lpo = izx.lijecenjePeriodOd.substring(0, 10)
        }
        if (izx.lijecenjePeriodDo != null && izx.lijecenjePeriodDo != "") {
            lpd = izx.lijecenjePeriodDo.substring(0, 10)
        }

        let iz = {
            datum: izx.datum.substring(0, 10) || "",
            dijagnoza_naziv: izx.dijagnozaNazivSrp || "",
            dijagnoza_oznaka: izx.dijagnoza || "",
            id: izx.id || "",
            broj: izx.broj.toString(),
            lijecenje_period_od: lpo,
            lijecenje_period_do: lpd,
            ljekar_ime_prezime: izx.ljekarImePrezime || "",
            ljekar_oznaka: izx.ljekarOznaka || "",
            osiguranik_ime_prezime: izx.osiguranikImePrezime || "",
            osiguranik_jmb: izx.osiguranikJmb || "",
            participacija_placa: pp || "",
            rok_placanja: izx.rokPlacanja.substring(0, 10) || "",
            uputnica_tip: izx.uputnicaTip || "",
            ustanova_uputilac_naziv: izx.ustanovaUputilacNaziv || "",
            ustanova_uputilac_sifra: izx.ustanovaUputilacSifra || "",
            valuta: "BAM",
            stavke: ls,
            nova_stavka: { ...prazna_stavka }
        }

        this.setState({ izvjestaj: iz })
    }

    retNullForE(element) {
        //console.log(element);
        let ret = null;
        if (element == null || element == undefined) {
            return null;
        }
        if (element.toString().trim() == "") {

            ret = null;
        }
        else ret = element;
        //console.log("A POSLALO:")
        //console.log(element);
        return ret;
    }

    sacuvajIzvjestaj(event) {
        this.checkIfLogged();
        let izv = { ... this.state.izvjestaj };
        let is_valid = validator(izv, toast);
        if (is_valid == false) {
            return;
        }

        let part_plac = false;
        if (izv.participacija_placa != null && izv.participacija_placa.trim() != "") {
            if (izv.participacija_placa == 'D') {
                part_plac = true;
            }
            else {
                part_plac = false;
            }
        }
        else {
            toast.error("Popunite polje: 'Participacija placa?'", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let suma_iznosa = 0;
        let suma_participacija = 0;
        let sklopljene_stavke = []
        for (let i = 0; i < izv.stavke.length; i++) {
            suma_iznosa = izv.stavke[i].usluga_bodova;
            suma_participacija = izv.stavke[i].usluga_participacija_iznos;
            let skl = {

                //id:izv.stavke[i].id,
                iznos: izv.stavke[i].usluga_bodova,
                kolicina: 1,
                participacijaIznos: izv.stavke[i].usluga_participacija_iznos,
                participacijaPlaca: part_plac,
                uslugaGrupa: izv.stavke[i].usluga_grupa,
                uslugaNivo: izv.stavke[i].usluga_nivo,
                //uslugaOznaka:izv.stavke[i].usluga_oznaka,
                //uslugaNaziv:izv.stavke[i].usluga_naziv
                ljekarSifra: izv.stavke[i].ljekar_sifra,
                usluga: this.retNullForE(izv.stavke[i].usluga_cjenovnik_id),
                id: this.retNullForE(izv.stavke[i].usluga_history_id),
                //id:id,
            }
            sklopljene_stavke.push(skl);
        }



        let zs = {
            id: izv.id,
            broj: Number(izv.broj),
            datum: izv.datum,
            dijagnoza: this.retNullForE(izv.dijagnoza_oznaka),
            dijagnozaNaziv: this.retNullForE(izv.dijagnoza_naziv),
            iznos: this.retNullForE(suma_iznosa),
            lijecenjePeriodOd: this.retNullForE(izv.lijecenje_period_od.toString()),
            lijecenjePeriodDo: this.retNullForE(izv.lijecenje_period_do.toString()),
            ljekarImePrezime: this.retNullForE(izv.ljekar_ime_prezime),
            ljekarOznaka: this.retNullForE(izv.ljekar_oznaka),
            osiguranikImePrezime: this.retNullForE(izv.osiguranik_ime_prezime),
            osiguranikJmb: this.retNullForE(izv.osiguranik_jmb),
            participacijaIznos: this.retNullForE(suma_participacija),
            participacijaPlaca: this.retNullForE(part_plac),
            rokPlacanja: izv.rok_placanja,
            uputnicaTip: this.retNullForE(izv.uputnica_tip),
            ustanovaUputilacNaziv: this.retNullForE(izv.ustanova_uputilac_naziv),
            ustanovaUputilacSifra: this.retNullForE(izv.ustanova_uputilac_sifra),
            valuta: 'BAM',
            stavke: sklopljene_stavke
        }

        //console.log(zs);

        fetch(BACKEND_URL + '/api/data/izvjestaj', {
            method: 'POST',
            headers:
            {
                //'Access-Control-Allow-Origin': 'http://beemed.xyz:7070',
                //'no-cors':true,

                'Accept': 'application/json',
                'Content-Type': 'application/json',
                credentials: 'include'
            },
            credentials: 'include',
            body: JSON.stringify(zs),
            //body: JSON.stringify(this.state),
        }).then(response => {
            //console.log(response);
            if (response.status == 200) {

                toast.success("Izvještaj sačuvan!", {
                    position: toast.POSITION.TOP_RIGHT
                });
                this.clearAll();
                this.first50(null);
                return;
            }
            else {
                //console.log("Greska pri cuvanju:::")
                //console.log(response.json())
                toast.error("Greska pri čuvanju! Provjerite unesene podatke!", {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }
        })//.then(response => response.json())
            //.then(data => {console.log("RESPONSE DATA: "); console.log(data)})
            .catch(error => {
                console.log(error)
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            });
        //.then(response => {response.json(); console.log(response)})

    }


    clearAll() {
        let prazan_obj = { ... this.izvjestaj_obj };
        prazan_obj['stavke'] = [];
        let prazna_stavka = { usluga_puna_oznaka: "", usluga_history_id: "", usluga_cjenovnik_id: "", idUsluge: null, usluga_grupa: "", id: null, usluga_nivo: "", usluga_oznaka: "", usluga_naziv: "", usluga_bodova: "", ljekar_sifra: "K0656", usluga_participacija_iznos: "", usluga_participacija_placa: "" }
        prazan_obj['nova_stavka'] = { ...prazna_stavka };
        this.setState({ izvjestaj: { ...prazan_obj } });
    }

    validateInputs(event) {
        /*
        console.log("activated");

        toast.error("Hello there m8 !", {
            position: toast.POSITION.TOP_RIGHT
          });*/
        validator({ ... this.state.izvjestaj }, toast);
    }

    handleLjekarSifra(event) {
        let izv = this.state.izvjestaj;
        izv.nova_stavka.ljekar_sifra = event.target.value;
        this.setState({ izvjestaj: izv });
    }

    handleNovaStavkaAdd(event) {
        this.checkIfLogged();

        let ns = { ... this.state.izvjestaj.nova_stavka };
        if (ns.usluga_naziv == null || ns.usluga_naziv.trim() == "" || ns.usluga_oznaka == null || ns.usluga_oznaka.trim() == "" || ns.usluga_bodova == null || ns.usluga_bodova.toString().trim() == "") {
            toast.error("Stavka (usluga) nije popunjena!", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        if(ns.ljekar_sifra == null || ns.ljekar_sifra.trim() == "" || ns.ljekar_sifra.length < 3 || ns.ljekar_sifra.length > 5) {
            toast.error("Sifra ljekara je obavezno polje duzine izmedju 3 i 5 karaktera.", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let izvjestajx = { ... this.state.izvjestaj };
        izvjestajx.stavke.push(ns);
        let prazna_stavka = { usluga_puna_oznaka: "", usluga_history_id: "", usluga_cjenovnik_id: "", idUsluge: null, usluga_grupa: "", id: null, usluga_nivo: "", usluga_oznaka: "", usluga_naziv: "", usluga_bodova: "", ljekar_sifra: "K0656", usluga_participacija_iznos: "", usluga_participacija_placa: "" }
        izvjestajx.nova_stavka = { ...prazna_stavka };
        this.setState({ izvjestaj: izvjestajx });
    }




    handleUputnicaTipChange(event) {
        let izv = { ...this.state.izvjestaj };
        izv.uputnica_tip = event.target.value
        this.setState({ izvjestaj: izv });
    }

    handleGrupaDjelatnostiChange(event) {
        //console.log(event.target);
        let izv = { ...this.state.izvjestaj };

        izv.grupa_djelatnosti_oznaka = event.target.value
        izv.grupa_djelatnosti_naziv = event.target.name;
        this.setState({ izvjestaj: izv });
    }

    handleInputChange(event) {
        let val = event.target.value;
        let name = event.target.name;
        let izv = { ... this.state.izvjestaj };
        //console.log("PODACI: ");
        //console.log(val);
        //console.log(name);
        //console.log(izv);
        izv[name] = val;
        if (name == 'datum') {
            izv['lijecenje_period_od'] = val;
            izv['lijecenje_period_do'] = val;
            this.setState({ izvjestaj: izv });
        }
        else {
            this.setState({ izvjestaj: izv });
        }

    }



    handleInputChange2(event) {
        let val = event.target.value;
        let name = event.target.name;
        //let st = {... this.state};
        //let izv = {... this.state.izvjestaj};
        //console.log("PODACI: ");
        //console.log(val);
        //console.log(name);
        //console.log(izv);
        //st[name]=val;
        this.setState({ [name]: val });
    }


    /*
     * let loading={
            izvjestaji_history:true,
            cjenovnik:true,
            dijagnoze:true,
            sifarnik_zdravstvenih_ustanova:true,
        }
     */
    componentDidMount() {

        fetch(BACKEND_URL + '/api/data/zu/composite', {
            method: 'GET',
            headers:
            {
                'credentials': 'include'
            },
            credentials: 'include'
        }).then(data => data.json())
            .then(data => {
                let new_st = { ... this.state };
                new_st['sifarnik_zdravstvenih_ustanova'] = data;
                new_st['loading']['sifarnik_zdravstvenih_ustanova'] = false;
                //this.setState({sifarnik_zdravstvenih_ustanova:data/*, valueOznaka:'', valueNaziv:''*/});
                this.setState({ ...new_st })
            })
            .catch(error => { console.log(error) });

        fetch(BACKEND_URL + '/api/data/cjenovnik/composite', {
            method: 'GET',
            headers:
            {
                'credentials': 'include'
            },
            credentials: 'include'
        }).then(data => data.json())
            .then(data => {
                let new_st = { ... this.state };
                new_st['cjenovnik'] = [...data];
                new_st['loading']['cjenovnik'] = false;
                this.setState({ ...new_st })
                //this.setState({cjenovnik:data});
            })
            .catch(error => { console.log(error) });
        fetch(BACKEND_URL + '/api/data/dijagnoze', {
            method: 'GET',
            headers:
            {
                'credentials': 'include'
            },
            credentials: 'include'
        }).then(data => data.json())
            .then(data => {
                let new_st = { ... this.state };
                new_st['dijagnoze'] = data;
                new_st['loading']['dijagnoze'] = false;
                this.setState({ ...new_st })
                //this.setState({dijagnoze:data/*, valueOznaka:'', valueNaziv:''*/});
            })
            .catch(error => { console.log(error) });
        fetch(BACKEND_URL + '/api/data/izvjestaj?page=0&size=50', {
            method: 'GET',
            headers:
            {
                'credentials': 'include'
            },
            credentials: 'include'
        }).then(data => data.json())
            .then(data => {
                let new_st = { ... this.state };
                for (let i = 0; i < data.length; i++) {
                    let iznos = 0;
                    let iznos_part = 0;
                    for (let y = 0; y < data[i].stavke.length; y++) {
                        iznos = iznos + data[i].stavke[y].iznos;
                        iznos_part = iznos_part + data[i].stavke[y].participacijaIznos;
                    }
                    data[i]['iznos'] = iznos;
                    if (data[i].participacijaPlaca == true) {
                        data[i]['iznos_part'] = iznos_part;
                        data[i]['part_plac'] = "DA";
                    }
                    else {
                        data[i]['iznos_part'] = 0;
                        data[i]['part_plac'] = "NE";
                    }
                }
                new_st['izvjestaji_history'] = data;
                new_st['loading']['izvjestaji_history'] = false;
                this.setState({ ...new_st })
                //this.setState({izvjestaji_history:data/*, valueOznaka:'', valueNaziv:''*/});
            })
            .catch(error => { console.log(error) });

        this.countThosePages();
        /*
                await fetch(BACKEND_URL + '/api/data/zu/composite', {
                    method:'GET',
                    headers:
                        {
                            'credentials':'include'
                        },
                    credentials:'include'
                })
                .then(response => 
                    {
                        console.log("CHECK IF USER IS LOGGED RESPONSE")
                        console.log(response);
                        if(response.status==200)
                        {
                            console.log("200");
                            let data = response.json();
                            console.log(data);
                            console.log("USER IS LOGGED");
                            zu_composite=data;
                            this.setState({prommised:data});
                        }
                        else
                        {
                            console.log("NOT 200");
                            //this.props.history.push('/');
                        }
                    }
                )*/
        /*
                fetch(BACKEND_URL + '/api/data/zu/composite', {
                    method:'GET',
                    headers:
                        {
                            'credentials':'include'
                        },
                    credentials:'include'
                }).then(data => data.json())
                .then(data => {
                    this.setState({sifarnik_zdravstvenih_ustanova:data});
                })
        */
        //console.log("ISPIS RESPA: ");
        //console.log(resp);
        //console.log(resp.json());
    }


    countThosePages() {
        fetch(BACKEND_URL + '/api/data/izvjestaj/count', {
            method: 'GET',
            headers:
            {
                'credentials': 'include'
            },
            credentials: 'include'
        }).then(data => data.json())
            .then(data => {
            /*this.setState({izvjestaji_history:data}*/ console.log(data)
                if (data != null) {
                    let br_izv = data;
                    if (br_izv == 0) {
                        this.setState({ currentPosition: 0, maxPosition: 0 })
                    }
                    else if (br_izv < 50 && br_izv > 0) {
                        let djeljenje = 1;
                        this.setState({ currentPosition: 0, maxPosition: djeljenje - 1 });
                    }
                    else if (br_izv > 0) {
                        let djeljenje = br_izv / 50;
                        console.log("DJELJENJE: ");
                        djeljenje = Math.floor((djeljenje + 1))
                        console.log(djeljenje);
                        this.setState({ currentPosition: 0, maxPosition: djeljenje - 1 });
                    }
                }


            })
            .catch(error => { console.log(error) });
    }

    componentWillMount() {

    }

    first50(event) {
        this.checkIfLogged();
        this.fetchIzv(0, 50);
        this.countThosePages();
        /*
                fetch(BACKEND_URL + '/api/data/izvjestaj?page=0&size=50', {
                    method:'GET',
                    headers:
                        {
                            'credentials':'include'
                        },
                    credentials:'include'
                }).then(data => data.json())
                .then(data => {
                    
                    let new_st = {... this.state};
                    console.log(data);
                    new_st['izvjestaji_history']=data;
                    new_st['loading']['izvjestaji_history']=false;
                    new_st['currentPosition']=0;
                    this.setState({... new_st})
                    toast.success("Prikaz izvještaja osvježen!", {
                        position: toast.POSITION.TOP_RIGHT
                      });
                })
                .catch(error => {console.log(error)});*/
    }
    previous50(event) {
        this.checkIfLogged();
        if (this.state.currentPosition > 0) {
            this.fetchIzv(this.state.currentPosition - 1, 50)
            this.countThosePages();
        }
        else {
            toast.error("Nema prethodnih izvjestaja!", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        /*
        if(this.state.currentPosition>0)
        {
            fetch(BACKEND_URL + '/api/data/izvjestaj?page='+(this.state.currentPosition-1).toString()+'&size=50', {
                method:'GET',
                headers:
                    {
                        'credentials':'include'
                    },
                credentials:'include'
            }).then(data => data.json())
            .then(data => {
                let old_cp = this.state.currentPosition;
                this.setState({izvjestaji_history:data, currentPosition:old_cp-1 } );
            })
            .catch(error => {console.log(error)});
        }
        else
        {
            toast.error("Nema prethodnih izvjestaja!", {
                position: toast.POSITION.TOP_RIGHT
              });
            return;
        }*/
    }
    next50(event) {
        if (this.state.currentPosition < this.state.maxPosition) {
            this.fetchIzv(this.state.currentPosition + 1, 50);
            this.countThosePages();
        }
        else {
            toast.error("Nemate vise izvjestaja!", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        /*
        this.checkIfLogged();
        if(this.state.currentPosition<this.state.maxPosition)
        {
            fetch(BACKEND_URL + '/api/data/izvjestaj?page='+(this.state.currentPosition+1).toString()+'&size=50', {
                method:'GET',
                headers:
                    {
                        'credentials':'include'
                    },
                credentials:'include'
            }).then(data => data.json())
            .then(data => {
                let old_cp = this.state.currentPosition;
                this.setState({izvjestaji_history:data, currentPosition:old_cp+1} );
            })
            .catch(error => {console.log(error)});
        }
        else
        {
            toast.error("Nemate vise izvjestaja!", {
                position: toast.POSITION.TOP_RIGHT
              });
            return;
        }*/
    }
    last50(event) {
        this.fetchIzv(this.state.maxPosition, 50);
        this.countThosePages();
        /*
        this.checkIfLogged();
        
            fetch(BACKEND_URL + '/api/data/izvjestaj?page='+this.state.maxPosition.toString()+'&size=50', {
                method:'GET',
                headers:
                    {
                        'credentials':'include'
                    },
                credentials:'include'
            }).then(data => data.json())
            .then(data => {
                let old_cp = this.state.maxPosition;
                this.setState({izvjestaji_history:data, currentPosition:old_cp } );
            })
            .catch(error => {console.log(error)});
        */
    }


    fetchIzv(page, size) {
        this.checkIfLogged();
        fetch(`/api/data/izvjestaj?page=${page}&size=${size}`, {
            method: 'GET',
            headers:
            {
                'credentials': 'include'
            },
            credentials: 'include'
        }).then(data => data.json())
            .then(data => {
                let new_st = { ... this.state };
                //console.log(data);

                for (let i = 0; i < data.length; i++) {
                    let iznos = 0;
                    let iznos_part = 0;
                    for (let y = 0; y < data[i].stavke.length; y++) {
                        iznos = iznos + data[i].stavke[y].iznos;
                        iznos_part = iznos_part + data[i].stavke[y].participacijaIznos;
                    }
                    data[i]['iznos'] = iznos;
                    if (data[i].participacijaPlaca == true) {
                        data[i]['iznos_part'] = iznos_part;
                        data[i]['part_plac'] = "DA";
                    }
                    else {
                        data[i]['iznos_part'] = 0;
                        data[i]['part_plac'] = "NE";
                    }
                }

                new_st['izvjestaji_history'] = data;
                new_st['loading']['izvjestaji_history'] = false;
                new_st['currentPosition'] = page;
                this.setState({ ...new_st })
                toast.success("Prikaz izvještaja osvježen!", {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
            .catch(error => { console.log(error) });
    }

    render() {
        //this.checkIfLogged();
        console.log("THIS STATE ON RENDER: ");
        console.log(this.state);
        //console.log(this.state.dijagnoze.length);

        let rend_state = { ... this.state };

        let to_ret = null;


        if (this.state.loading.izvjestaji_history == true ||
            this.state.loading.sifarnik_zdravstvenih_ustanova == true ||
            this.state.loading.cjenovnik == true ||
            this.state.loading.dijagnoze == true) {
            to_ret = <div className="bg-light" height="100%" width="100%" style={{ margin: 0, height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', }}>

                <AppNavbar />
                <ToastContainer autoClose={8000} />
                <Container className="bg-light">
                    <h1>Stranica se osvježava</h1>
                </Container>
            </div>
        }
        else {
            to_ret = <div className="bg-light" >

                <AppNavbar />
                <ToastContainer autoClose={8000} />
                <p className="text-danger">*Napomena: Zbog različitih podešavanja, različitih pretraživača, moguće je da će se određeni foramti datuma pojaviti u formatu GGGG/MM/DD ili MM/DD/YYYY umjesto standardnog DD/MM/GGGG te Vas molimo da obratite pažnju ... => Vi odaberite onako kako Vam kalendar nudi a u bazu će se svakako upisati ispravni datumi</p>
                <p>Uputstvo za korištenje aplikacije možete preuzeti : <a className="btn btn-info" href={`/static/dokumenti/uputstvo.pdf`} download target="_blank"  >Na ovoj adresi</a></p>


                <Table>
                    <tbody>
                        <tr>
                            <td style={{ 'width': '300px' }}>
                                <nav aria-label="Page navigation example">
                                    Stranica: {this.state.currentPosition}/{this.state.maxPosition}
                                    <ul class="pagination">
                                        <li class="page-item"><a class="page-link" onClick={this.first50} href="#">Prvih 50 izv.</a></li>
                                        <li class="page-item"><a class="page-link" onClick={this.previous50} href="#">Prošlih 50 izv.</a></li>
                                        <li class="page-item"><a class="page-link" onClick={this.next50} href="#">Sledećih 50 izv.</a></li>
                                        <li class="page-item"><a class="page-link" onClick={this.last50} href="#">Zadnjih 50 izv.</a></li>
                                    </ul>
                                </nav>
                                <div style={{ 'height': '500px', 'overflow': 'scroll' }}>
                                    <Table >
                                        <thead>
                                            <tr>
                                                <th>Izaberi</th>
                                                <th>Broj</th>
                                                <th>Datum (godina-mjesec-dan)</th>
                                                <th>Osig. ime, prezime</th>
                                                <th>Osig. jmb</th>
                                                <th>Iznos</th>
                                                <th>Placa part.?</th>
                                                <th>Iznos part.</th>
                                                <th>Rok placanja (gggg-mm-dd)</th>
                                                <th>Dijagnoza</th>
                                            </tr>
                                            {

                                                this.state.izvjestaji_history.map((stavka, i) => {
                                                console.log(stavka);
                                                    return (<tr>
                                                        <td><Button className="btn btn-primary" name={i.toString()} onClick={this.izmjeniIzvjestaj}>Izmjeni</Button></td>
                                                        <td>{stavka.broj}</td>
                                                        <td>{stavka.datum.substring(0, 10)}</td>
                                                        <td>{stavka.osiguranikImePrezime}</td>
                                                        <td>{stavka.osiguranikJmb}</td>
                                                        <td>~{stavka.iznos}</td>
                                                        <td>{stavka.part_plac}</td>
                                                        <td>~{stavka.iznos_part}</td>
                                                        <td>{stavka.rokPlacanja.substring(0, 10)}</td>
                                                        <td>{stavka.dijagnoza}</td>
                                                    </tr>)
                                                })

                                            }
                                        </thead>
                                        <tbody>

                                        </tbody>
                                    </Table>
                                </div>
                                <hr />
                                <Button className="btn btn-success" onClick={this.noviIzvjestaj}>Kreiraj Novi izvještaj</Button>
                                <hr />
                                <h2>Generisanje izvještaja</h2>
                                <hr />
                                <div>Datum od:<Input type="date" name="datum_od_za_arhivu" id="datum_od_za_arhivu" value={this.state.datum_od_za_arhivu} onChange={this.handleInputChange2}></Input></div>
                                <div>Datum do:<Input type="date" name="datum_do_za_arhivu" id="datum_do_za_arhivu" value={this.state.datum_do_za_arhivu} onChange={this.handleInputChange2}></Input></div>

                                <div class="dropdown">
                                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Generiši
                                </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

                                        <a className="btn btn-info dropdown-item" href={`/api/data/izvjestaj/generate/zip?from=${this.state.datum_od_za_arhivu}&to=${this.state.datum_do_za_arhivu}`} download target="_blank"  >Zapakovan XML</a>
                                        <a className="btn btn-info dropdown-item" href={`/api/data/izvjestaj/generate/html?type=basic&from=${this.state.datum_od_za_arhivu}&to=${this.state.datum_do_za_arhivu}`} target="_blank" >Uljepšan prikaz za štampanje</a>
                                        <a className="btn btn-info dropdown-item" href={`/api/data/izvjestaj/generate/html?type=extended&from=${this.state.datum_od_za_arhivu}&to=${this.state.datum_do_za_arhivu}`} target="_blank" >Uljepšan prikaz za štampanje (Proširen)</a>
                                    </div>
                                </div>
                                <hr />
                                <h2>Nova opcija za štampanje dnevnih izvještaja</h2>
                                <p className="text-danger">*Napomena: Za svaki dan koji želite da štampate kao dnevni izvještaj odaberite datum tog dana te kliknite na dugme ispod. Proces ponoviti za svaki dan koji želite da se štampa kao dnevni izvještaj tj. za svaki dan u mjesecu.</p>
                                <div>Dnevni izvještaj za dan:<Input type="date" name="datum_dnevni" id="datum_dnevni" value={this.state.datum_dnevni} onChange={this.handleInputChange2}></Input></div>
                                <a className="btn btn-primary" color="primary"
                                    href={`/api/data/izvjestaj/generate/html?type=daily&from=${this.state.datum_dnevni}`} target="_blank"
                                >Generiši dnevni izvještaj za štampanje</a>

                            </td>
                            <td >
                                <Container className="bg-light">
                                    <h2>Osnovni podaci</h2>
                                    <form action="">
                                        <Table className="bg-light">
                                            <tr>
                                                <td><Label><b>Broj fakture</b></Label></td>
                                                <td><Input type="number" name="broj" id="broj" value={this.state.izvjestaj.broj} onChange={this.handleInputChange}></Input></td>
                                            </tr>
                                            <tr>
                                                <td><Label><b>Osiguranik jmb</b></Label></td>
                                                <td><Input type="text" name="osiguranik_jmb" id="osiguranik_jmb" value={this.state.izvjestaj.osiguranik_jmb} onChange={this.handleInputChange}></Input></td>
                                                <td><Label><b>Ime i prezime osiguranika</b></Label></td>
                                                <td><Input type="text" name="osiguranik_ime_prezime" id="osiguranik_ime_prezime" value={this.state.izvjestaj.osiguranik_ime_prezime} onChange={this.handleInputChange}></Input></td>
                                            </tr>
                                            <tr>
                                                <td><Label><b>Datum pregleda</b> (format datuma zavisi od vašeg pretraživača)</Label></td>
                                                <td><Input type="date" name="datum" id="datum" value={this.state.izvjestaj.datum} onChange={this.handleInputChange}></Input></td>
                                                <td><Label><b>Rok plaćanja</b> (format datuma zavisi od vašeg pretraživača)</Label></td>
                                                <td><Input type="date" name="rok_placanja" id="rok_placanja" value={this.state.izvjestaj.rok_placanja} onChange={this.handleInputChange}></Input></td>
                                            </tr>
                                            <tr>
                                                <td><Label ><b>Oznaka dijagnoze</b></Label></td>
                                                <td><Autocomplete className="form-controll"
                                                    value={this.state.izvjestaj.dijagnoza_oznaka}
                                                    inputProps={{ id: 'states-autocomplete' }}
                                                    wrapperStyle={{ position: 'relative', display: 'inline-block' }}
                                                    items={ /*getStocks()*/this.state.dijagnoze}
                                                    getItemValue={item => item.oznaka}
                                                    shouldItemRender={matchDijagnozaOznaka}
                                                    onChange={(event, value) => {
                                                        let izv = this.state.izvjestaj;
                                                        izv.dijagnoza_oznaka = value;
                                                        this.setState({ izvjestaj: izv });
                                                    }/* this.setState({ valueOznaka: value.toString() })*/}
                                                    onSelect={(value, item) => {
                                                        let izv = this.state.izvjestaj;
                                                        izv.dijagnoza_oznaka = value;
                                                        izv.dijagnoza_naziv = item.srp;
                                                        this.setState({ izvjestaj: izv });
                                                    } /*this.setState({ valueOznaka: value.toString(), valueNaziv:item.naziv })*/}
                                                    renderMenu={children => (
                                                        <div className="menu">
                                                            {children}
                                                        </div>
                                                    )}
                                                    renderItem={(item, isHighlighted) => (
                                                        <div
                                                            className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                                            key={item.oznaka.toString()} >
                                                            {item.oznaka.toString() + '-' + item.srp}
                                                        </div>
                                                    )}
                                                />
                                                </td>
                                                <td><Label><b>Naziv dijagnoze</b></Label></td>
                                                <td><Autocomplete
                                                    value={this.state.izvjestaj.dijagnoza_naziv}
                                                    inputProps={{ id: 'states-autocomplete' }}
                                                    wrapperStyle={{ position: 'relative', display: 'inline-block' }}
                                                    items={ /*getStocks()*/this.state.dijagnoze}
                                                    getItemValue={item => item.srp}
                                                    shouldItemRender={matchDijagnozaNaziv}
                                                    onChange={(event, value) => {
                                                        let izv = this.state.izvjestaj;
                                                        izv.dijagnoza_naziv = value;
                                                        this.setState({ izvjestaj: izv });
                                                    } /*this.setState({valueNaziv: value })*/}
                                                    onSelect={(value, item) => {
                                                        let izv = this.state.izvjestaj;
                                                        izv.dijagnoza_naziv = value;
                                                        izv.dijagnoza_oznaka = item.oznaka;
                                                        this.setState({ izvjestaj: izv });
                                                    } /*{this.setState({valueNaziv: value, valueOznaka: item.oznaka.toString() }); console.log(item); }*/}
                                                    renderMenu={children => (
                                                        <div className="menu">
                                                            {children}
                                                        </div>
                                                    )}
                                                    renderItem={(item, isHighlighted) => (
                                                        <div
                                                            className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                                            key={item.oznaka} >
                                                            {item.srp}
                                                        </div>
                                                    )}
                                                />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><Label><b>Liječenje period od </b> (format datuma zavisi od vašeg pretraživača)</Label></td>
                                                <td><Input type="date" name="lijecenje_period_od" id="lijecenje_period_od" value={this.state.izvjestaj.lijecenje_period_od} onChange={this.handleInputChange}></Input></td>
                                                <td><Label><b>Liječenje period do </b> (format datuma zavisi od vašeg pretraživača)</Label></td>
                                                <td><Input type="date" name="lijecenje_period_do" id="lijecenje_period_do" value={this.state.izvjestaj.lijecenje_period_do} onChange={this.handleInputChange}></Input></td>
                                            </tr>
                                            <tr>
                                                <td><Label ><b>Šifra ustanove uputioca</b></Label></td>
                                                <td><Autocomplete className="form-controll"
                                                    value={this.state.izvjestaj.ustanova_uputilac_sifra.toString()}
                                                    inputProps={{ id: 'states-autocomplete' }}
                                                    wrapperStyle={{ position: 'relative', display: 'inline-block' }}
                                                    items={ /*getStocks()*/this.state.sifarnik_zdravstvenih_ustanova}
                                                    getItemValue={item => item.oznaka.toString()}
                                                    shouldItemRender={matchOznaka}
                                                    onChange={(event, value) => {
                                                        let izv = this.state.izvjestaj;
                                                        izv.ustanova_uputilac_sifra = value.toString();
                                                        this.setState({ izvjestaj: izv });
                                                    }/* this.setState({ valueOznaka: value.toString() })*/}
                                                    onSelect={(value, item) => {
                                                        let izv = this.state.izvjestaj;
                                                        izv.ustanova_uputilac_sifra = value.toString();
                                                        izv.ustanova_uputilac_naziv = item.naziv;
                                                        this.setState({ izvjestaj: izv });
                                                    } /*this.setState({ valueOznaka: value.toString(), valueNaziv:item.naziv })*/}
                                                    renderMenu={children => (
                                                        <div className="menu">
                                                            {children}
                                                        </div>
                                                    )}
                                                    renderItem={(item, isHighlighted) => (
                                                        <div
                                                            className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                                            key={item.oznaka.toString()} >
                                                            {item.oznaka.toString() + '-' + item.naziv}
                                                        </div>
                                                    )}
                                                />
                                                </td>
                                                <td><Label><b>Naziv ustanove uputioca</b></Label></td>
                                                <td><Autocomplete
                                                    value={this.state.izvjestaj.ustanova_uputilac_naziv}
                                                    inputProps={{ id: 'states-autocomplete' }}
                                                    wrapperStyle={{ position: 'relative', display: 'inline-block' }}
                                                    items={ /*getStocks()*/this.state.sifarnik_zdravstvenih_ustanova}
                                                    getItemValue={item => item.naziv}
                                                    shouldItemRender={matchNaziv}
                                                    onChange={(event, value) => {
                                                        let izv = this.state.izvjestaj;
                                                        izv.ustanova_uputilac_naziv = value;
                                                        this.setState({ izvjestaj: izv });
                                                    } /*this.setState({valueNaziv: value })*/}
                                                    onSelect={(value, item) => {
                                                        let izv = this.state.izvjestaj;
                                                        izv.ustanova_uputilac_naziv = value;
                                                        izv.ustanova_uputilac_sifra = item.oznaka.toString();
                                                        this.setState({ izvjestaj: izv });
                                                    } /*{this.setState({valueNaziv: value, valueOznaka: item.oznaka.toString() }); console.log(item); }*/}
                                                    renderMenu={children => (
                                                        <div className="menu">
                                                            {children}
                                                        </div>
                                                    )}
                                                    renderItem={(item, isHighlighted) => (
                                                        <div
                                                            className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                                            key={item.oznaka} >
                                                            {item.naziv}
                                                        </div>
                                                    )}
                                                />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><Label><b>Tip uputnice</b></Label></td>
                                                <td>
                                                    <select value={this.state.izvjestaj.uputnica_tip} onChange={this.handleUputnicaTipChange}>
                                                        <option value="S">S</option>
                                                        <option value="B">B</option>
                                                    </select>
                                                </td>
                                                <td><Label><b>Participaciju Plaća?</b></Label></td>
                                                <td>
                                                    <select value={this.state.izvjestaj.participacija_placa} onChange={this.handlePPChange}>
                                                        <option value="D">D</option>
                                                        <option value="N">N</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><Label><b>Ljekar uputioc oznaka</b></Label></td>
                                                <td><Input type="text" name="ljekar_oznaka" id="ljekar_oznaka" value={this.state.izvjestaj.ljekar_oznaka} onChange={this.handleOznakaChange}></Input></td>
                                                <td><Label><b>Ime i prezime ljekara uputioca</b></Label></td>
                                                <td><Input type="text" name="ljekar_ime_prezime" id="ljekar_ime_prezime" value={this.state.izvjestaj.ljekar_ime_prezime} onChange={this.handleInputChange}></Input></td>
                                            </tr>
                                        </Table>
                                    </form>
                                    <hr />
                                    <Container className="bg-light">
                                        <div style={{ height: '300px', overflow: 'scroll' }}>
                                            <Table className="">
                                                <thead>
                                                    <tr>
                                                        <th>Puna oznaka</th>
                                                        <th>Ljekar</th>
                                                        <th>Bodova</th>
                                                        <th>Part. Iznos</th>
                                                        <th>Usluga nivo</th>
                                                        <th>Usluga grupa</th>
                                                        <th>Usluga oznaka</th>
                                                        <th>Usluga naziv</th>
                                                        <th>Obrisi?</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.izvjestaj.stavke.map((stavka, i) => {
                                                            return (<tr>
                                                                <td>{stavka.usluga_puna_oznaka}</td>
                                                                <td>{stavka.ljekar_sifra}</td>
                                                                <td>{stavka.usluga_bodova}</td>
                                                                <td>{stavka.usluga_participacija_iznos}</td>
                                                                <td>{stavka.usluga_nivo}</td>
                                                                <td>{stavka.usluga_grupa}</td>
                                                                <td>{stavka.usluga_oznaka}</td>
                                                                <td>{stavka.usluga_naziv}</td>
                                                                <td><Button className="btn btn-danger" name={i.toString()} onClick={this.obrisiStavku}>Obrisi</Button></td>
                                                            </tr>)
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        <Table className="">
                                            <tr>
                                                <td><Label ><b>Puna Oznaka usluge (7 cif.)</b></Label></td>
                                                <td><Autocomplete className="form-controll"
                                                    value={this.state.izvjestaj.nova_stavka.usluga_puna_oznaka}
                                                    inputProps={{ id: 'states-autocomplete3' }}
                                                    wrapperStyle={{ position: 'relative', display: 'inline-block' }}
                                                    items={ /*getStocks()*/this.state.cjenovnik}
                                                    getItemValue={item => item.punaOznaka}
                                                    shouldItemRender={matchCjenovnikPunaOznaka}
                                                    onChange={(event, value) => {
                                                        let izv = this.state.izvjestaj;
                                                        izv.nova_stavka.usluga_puna_oznaka = value;
                                                        this.setState({ izvjestaj: izv });
                                                    }/* this.setState({ valueOznaka: value.toString() })*/}
                                                    onSelect={(value, item) => {
                                                        let izv = this.state.izvjestaj;
                                                        let it = item
                                                        izv.nova_stavka.usluga_puna_oznaka = value;
                                                        izv.nova_stavka.usluga_cjenovnik_id = it.id;
                                                        izv.nova_stavka.usluga_naziv = it.naziv;
                                                        izv.nova_stavka.usluga_bodova = it.bodova;
                                                        izv.nova_stavka.usluga_participacija_iznos = it.participacija;
                                                        izv.nova_stavka.usluga_participacija_placa = rend_state.izvjestaj.participacija_placa;
                                                        izv.nova_stavka.usluga_grupa = it.punaOznaka.substring(1, 4);
                                                        izv.nova_stavka.usluga_nivo = it.punaOznaka.substring(0, 1);
                                                        izv.nova_stavka.usluga_oznaka = it.oznaka;
                                                        this.setState({ izvjestaj: izv });
                                                    }}
                                                    renderMenu={children => (
                                                        <div className="menu">
                                                            {children}
                                                        </div>
                                                    )}
                                                    renderItem={(item, isHighlighted) => (
                                                        <div
                                                            className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                                            key={item.punaOznaka.toString() + getRandomNumStr().toString() + getRandomNumStr().toString() + getRandomNumStr().toString() + getRandomNumStr().toString() + getRandomNumStr().toString()} >
                                                            {item.punaOznaka.toString() + '-' + item.naziv.toString()}
                                                        </div>
                                                    )}
                                                />
                                                </td>
                                                <td><Label><b>Naziv usluge</b></Label></td>
                                                <td><Autocomplete
                                                    value={this.state.izvjestaj.nova_stavka.usluga_naziv}
                                                    inputProps={{ id: 'states-autocomplete' }}
                                                    wrapperStyle={{ position: 'relative', display: 'inline-block' }}
                                                    items={ /*getStocks()*/this.state.cjenovnik}
                                                    getItemValue={item => item.naziv}
                                                    shouldItemRender={matchCjenovnikNaziv}
                                                    onChange={(event, value) => {
                                                        let izv = this.state.izvjestaj;
                                                        izv.nova_stavka.usluga_naziv = value;
                                                        this.setState({ izvjestaj: izv });
                                                    } /*this.setState({valueNaziv: value })*/}
                                                    onSelect={(value, item) => {
                                                        let izv = this.state.izvjestaj;
                                                        let it = item;
                                                        izv.nova_stavka.usluga_naziv = value;
                                                        izv.nova_stavka.usluga_cjenovnik_id = it.id;
                                                        izv.nova_stavka.usluga_puna_oznaka = it.punaOznaka;
                                                        izv.nova_stavka.usluga_bodova = it.bodova;
                                                        izv.nova_stavka.usluga_participacija_iznos = it.participacija;
                                                        izv.nova_stavka.usluga_participacija_placa = rend_state.izvjestaj.participacija_placa;
                                                        izv.nova_stavka.usluga_grupa = it.punaOznaka.substring(1, 4);
                                                        izv.nova_stavka.usluga_nivo = it.punaOznaka.substring(0, 1);
                                                        izv.nova_stavka.usluga_oznaka = it.oznaka;
                                                        this.setState({ izvjestaj: izv });
                                                    }}
                                                    renderMenu={children => (
                                                        <div className="menu">
                                                            {children}
                                                        </div>
                                                    )}
                                                    renderItem={(item, isHighlighted) => (
                                                        <div
                                                            className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                                            key={item.punaOznaka.toString() + getRandomNumStr().toString() + getRandomNumStr().toString() + getRandomNumStr().toString() + getRandomNumStr().toString() + getRandomNumStr().toString()} >
                                                            {item.naziv.toString()}
                                                        </div>
                                                    )}
                                                />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Sifra Ljekara:</td>
                                                <td colspan={3}>
                                                    <Input
                                                        type="text"
                                                        value={this.state.izvjestaj.nova_stavka.ljekar_sifra}
                                                        onChange={this.handleLjekarSifra}
                                                        />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><Label>Iznos (Bodova):</Label></td>
                                                <td><Input readOnly value={this.state.izvjestaj.nova_stavka.usluga_bodova}></Input></td>
                                                <td><Label>Participacija: </Label></td>
                                                <td><Input readOnly value={this.state.izvjestaj.nova_stavka.usluga_participacija_iznos}></Input></td>
                                            </tr>
                                            <tr>
                                                <td><Label>Nivo/Grupa/Oznaka: </Label></td>
                                                <td><Input readOnly value={this.state.izvjestaj.nova_stavka.usluga_nivo}></Input></td>
                                                <td><Input readOnly value={this.state.izvjestaj.nova_stavka.usluga_grupa}></Input></td>

                                                <td><Input readOnly value={this.state.izvjestaj.nova_stavka.usluga_oznaka}></Input></td>
                                            </tr>

                                        </Table>
                                        <Button className="btn btn-success" onClick={this.handleNovaStavkaAdd}>Dodaj ovu uslugu</Button>
                                    </Container>
                                    <hr />
                                    <Button color="info" onClick={this.validateInputs}>Validiraj</Button>{'   '}
                                    <Button className="btn btn-primary" type="button" color="primary" onClick={this.sacuvajIzvjestaj}>Sačuvaj izvještaj</Button>{'   '}
                                    <Button className="btn btn-danger" onClick={this.obrisiIzvjestaj}>Obriši izvještaj</Button>
                                </Container>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <p>U slučaju bilo kakvih problema, obratite se na: <a href="mailto:kontakt@fakture.info?Subject=Pomoc" target="_top">kontakt@fakture.info</a></p>

            </div>
        }

        return (
            to_ret
        );
    }
}

export default Izvjestaji;
//export default
