import BACKEND_URL from './Config.js';

export default  function checkIfUserIsLogged()
{
    let resp_val=null;
     let resp=  fetch(BACKEND_URL + '/api/user/me', {
                method:'GET',
                headers:
                    {
                        'credentials':'include'
                    },
                credentials:'include'
            })
            .then(response => 
                {
                    console.log("CHECK IF USER IS LOGGED RESPONSE")
                    console.log(response);
                    //console.log(response.status);
                    if(response.status==200)
                    {
                        console.log("USER IS LOGGED");
                        resp_val=true;
                        return true;
                        //return true;
                    }
                    else
                    {
                        console.log("USER IS NOT LOGGED");
                        resp_val=false;
                        return false;
                        
                        //return false;
                    }
                }
                
    )
    console.log("SOME RESP:");
    console.log(resp)
    console.log("SOME RESP_val:");
    console.log(resp_val)
    return resp_val;
    //return resp;
/*
    let response = await fetch(BACKEND_URL + '/api/user/me', {
        method:'GET',
        headers:
            {
                //'Access-Control-Allow-Origin': 'http://beemed.xyz:7070',
                //'no-cors':true,
                'credentials':'include'
            },
            //'mode':'cors',
        //'no-cors':true,
        credentials:'include'
    })

    if(response.status==200)
                    {
                        console.log("USER IS LOGGED");
                        //resp=true;
                        return true;
                    }
                    else
                    {
                        console.log("USER IS NOT LOGGED");
                        //resp=false;
                        return false;
                    }
                    */
}