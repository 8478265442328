import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Button, Modal, ModalHeader, ModalBody, Container, Form, FormGroup, Table, Label, Input} from 'reactstrap';
import AppNavbar from './AppNavbar';
import loggoutUser from './Logout';
import checkIfUserIsLogged from './CheckIfLogged'
import BACKEND_URL from './Config.js';

class TestOK extends Component{

    constructor(props){
        super(props);
        //this.handleMailInputChange = this.handleMailInputChange.bind(this);
        //this.handlePassInputChange = this.handlePassInputChange.bind(this);
        //this.handleLogout = this.handleLogout.bind(this);

        fetch(BACKEND_URL + '/api/user/me', {
            method:'GET',
            headers:
                {
                    'credentials':'include'
                },
            credentials:'include'
        })
        .then(response => 
            {
                console.log("CHECK IF USER IS LOGGED RESPONSE")
                console.log(response);
                if(response.status==200)
                {
                    console.log("USER IS LOGGED");
                }
                else
                {
                    console.log("USER IS NOT LOGGED");
                    this.props.history.push('/');
                }
            }
        )
        
    }

    //component

    componentDidMount()
    {/*
        let cil = checkIfUserIsLogged();
        console.log("IZ test OK cil: "+cil)*/
        
    }
/*
    async handleLogout(event)
    {
        loggoutUser();
    }*/
    componentDidMount()
    {

    }
    

    render()
    {
        console.log(this.state)

        
        //
        return(
            <div className="bg-dark" height="100%" width="100%" style={{ margin:0, height:'100%', width:'100%', justifyContent:'center', alignItems:'center', }}>
                <AppNavbar/>
                <h1 className="text-success">TestOK</h1>
                
            </div>
        );
    }
}

export default TestOK;